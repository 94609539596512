@import "../../global.scss";

.works {
  background-color: $secondaryColor;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: $mainColor;

  h1 {
    margin-bottom: 75vh;
    font-size: 80px;
    z-index: 5;
    font-family: "VT323", monospace;
    font-weight: 400;
    font-style: normal;

    @include mobile {
      font-size: 40px;
      margin-bottom: 85vh;
    }
  }

  .arrow {
    height: 70px;
    position: absolute;
    cursor: pointer;

    @include mobile {
      display: none;
    }

    &.left {
      left: 100px;
      top: 380px;
      transform: rotate(180deg);
    }

    &.right {
      right: 100px;
      top: 380px;
    }
  }

  .slider {
    height: 550px;
    display: flex;
    position: absolute;
    left: 0;
    transition: all 1s ease-out;

    @include mobile {
      height: 100%;
      flex-direction: column;
      justify-content: center;
    }

    .container {
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;

      .item {
        width: 1280px;
        height: 100%;
        background-color: $secondaryColor;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
          width: 90%;
          height: 90%;
          margin: 5px 0;
        }

        .left {
          flex: 4;
          height: 80%;
          display: flex;
          align-items: center;
          justify-content: center;

          @include mobile {
            flex: 8;
          }

          .leftContainer {
            width: 90%;
            height: 70%;
            display: flex;
            flex-direction: column;

            @include mobile {
              width: 100%;
              height: 100%;
            }

            .imgContainer {
              height: 60px;
              width: 60px;

              @include mobile {
                display: none;
              }

              img {
                width: 50px;
                object-fit: fill;
              }
            }

            h2 {
              font-size: 30px;
              font-family: "VT323", monospace;
              font-weight: 400;
              font-style: normal;
              overflow: hidden;

              @include mobile {
                font-size: 15px;
              }
            }
            p {
              font-size: 22px;
              font-family: "VT323", monospace;
              font-weight: 400;
              font-style: normal;
              overflow: hidden;

              @include mobile {
                font-size: 8px;
              }
            }
            a {
              overflow: hidden;
            }
            span {
              font-size: 22px;
              font-weight: 600;
              text-decoration: underline;
              cursor: pointer;
              font-family: "VT323", monospace;
              font-weight: 400;
              font-style: normal;

              @include mobile {
                font-size: 15px;
              }
            }
          }
        }
        .right {
          flex: 8;
          height: 80%;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          img {
            width: 700px;
          }
        }
      }
    }
  }
}
