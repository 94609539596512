@import "../../global.scss";

.contact {
  background-color: $secondaryColor;
  display: flex;
  flex-direction: column;
  color: $mainColor;

  .left {
    flex: 1;
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;

    img {
      height: 70%;
    }
  }

  .right {
    flex: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 40px;
    }

    form {
      width: 70%;
      height: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      input {
        width: 350px;
        height: 30px;
        font-size: 14px;
      }

      textarea {
        width: 350px;
        height: 200px;
        font-size: 14px;
      }

      button {
        width: 150px;
        height: 30px;
        color: whitesmoke;
        background-color: crimson;
        border: none;
        border-radius: 10px;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
}
