@import "../../global.scss";

.portfolio {
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;

  .tabContainer {
    overflow: hidden;
  }

  h1 {
    font-size: 50px;
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: "VT323", monospace;
    font-weight: 400;
    font-style: normal;

    @include mobile {
      font-size: 40px;
    }
  }

  ul {
    margin: 20px;
    padding: 10px;
    list-style: none;
    display: flex;
    overflow: hidden;

    @include mobile {
      font-size: 20px;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 80%;
    overflow: hidden;

    @include mobile {
      width: 100%;
      height: 75%;
    }

    .item {
      border: 1px solid rgb(63, 63, 63);
      margin: 8px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      position: relative;
      overflow: hidden;
      transition: all 0.5s;
      cursor: pointer;

      @include mobile {
        margin-bottom: 10px;
        align-items: first baseline;
      }

      .embedYoutube {
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      &:hover {
        background-color: red;
      }
    }
  }
}
