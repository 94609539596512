@import "../../global.scss";

.intro {
  background-color: black;
  display: flex;
  color: white;

  @include mobile {
    flex-direction: column;
    align-items: center;
  }

  .left {
    flex: 1;
    overflow: hidden;

    .imgContainer {
      width: 100vb;
      height: 100vh;
      border-radius: 0%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5%;
      overflow: hidden;

      @include mobile {
        align-items: flex-start;
      }

      img {
        height: 90%;
        padding-bottom: 20vh;

        @include mobile {
          height: 50%;
          margin-bottom: 55vh;
        }
      }
    }
  }
  .right {
    flex: 1;

    .wrapper {
      width: 100%;
      height: 100%;
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mobile {
        padding-left: 0;
        align-items: center;
      }

      h1 {
        font-size: 100px;
        margin: 10px 0;
        font-family: "VT323", monospace;
        font-weight: 400;
        font-style: normal;

        @include mobile {
          font-size: 50px;
        }
      }

      h2 {
        font-size: 35px;
        font-family: "VT323", monospace;
        font-weight: 400;
        font-style: normal;
      }

      h3 {
        font-size: 30px;
        font-family: "VT323", monospace;
        font-weight: 400;
        font-style: normal;

        @include mobile {
          font-size: 20px;
        }

        TypeWriterEffect {
          color: rgb(0, 184, 216);
        }
      }
    }
    a {
      position: absolute;
      bottom: 10px;
      left: 60%;

      img {
        width: 30px;
        animation: arrowBlind 2s infinite;
      }

      @keyframes arrowBlind {
        100% {
          opacity: 0;
        }
      }
    }
  }
}
